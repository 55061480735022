import styled from "styled-components";
import spacing from "styles/spacing";
import { Category, Subject } from "types/attheminute";
import DefaultLink from "components/ui/Link";

const List = styled.ul<{ $isRoot?: boolean }>`
  ${(props) =>
    props.$isRoot
      ? `
    padding: 0;
    margin-block-start: 0.625rem;
    margin-block-end: 0.625rem;
  `
      : null}
`;

const ListItem = styled.li`
  list-style: none;
  padding: ${spacing.xxs} 0;
`;

const MenuLink = styled(DefaultLink)`
  padding-right: 1.875rem;
`;

// Intentionally kept this as "category tree" rather than "subject" since it is more generic
export const categoryTree = (
  categories: Category[],
  isRoot: boolean,
  currentSubject?: Subject
) => {
  return (
    <List $isRoot={isRoot}>
      {categories.map((category) => {
        if (category.children) {
          return (
            <ListItem key={category.id}>
              <MenuLink
                href={`/${category.fullSlug}`}
                isActive={
                  currentSubject &&
                  category.fullSlug === currentSubject?.fullSlug
                }
              >
                {category.name}
              </MenuLink>
              {categoryTree(category.children, false, currentSubject)}
            </ListItem>
          );
        } else {
          return (
            <ListItem key={category.id}>
              <MenuLink
                href={`/${category.fullSlug}`}
                isActive={
                  currentSubject &&
                  category.fullSlug === currentSubject?.fullSlug
                }
              >
                {category.name}
              </MenuLink>
            </ListItem>
          );
        }
      })}
    </List>
  );
};
