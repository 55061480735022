import Link from "next/link";
import { defaultMenuItems } from "components/ui/Navigation";
import DarkModeToggle from "components/ui/DarkModeToggle";

import * as Styled from "./Menu.styled";


interface DefaultMenuContentProps {
  toggleMenu: () => void;
}

export default function DefaultMenuContent(props: DefaultMenuContentProps) {
  const { toggleMenu } = props;
  return (
    <Styled.Content>
      {defaultMenuItems &&
        defaultMenuItems.map((menuItem) => (
          <Link key={menuItem.text} href={menuItem.link} passHref legacyBehavior>
            <Styled.MenuItemLink>
              {menuItem.emoji && (
                <Styled.Emoji role="img" aria-label="home">
                  {menuItem.emoji}
                </Styled.Emoji>
              )}
              {menuItem.text}
            </Styled.MenuItemLink>
          </Link>
        ))}
      <Styled.SubscribeButton onClick={toggleMenu} animate />
      <Styled.ToggleContainer>
        <DarkModeToggle />
      </Styled.ToggleContainer>
    </Styled.Content>
  );
}
