import styled from 'styled-components';
import screenSize from 'styles/mediaQueries';
import spacing from 'styles/spacing';
import layout from 'styles/layout';

export const Wrapper = styled.div`
  position: relative;
  max-width: ${layout.blogMaxWidth};
  justify-content: center;
  align-items: center;
  padding: ${spacing.lg};
  background: var(--color-lightShades);
  border-radius: 6px;

  ${screenSize.minTablet`
    max-width: ${layout.blogMaxWidth};
    width: ${layout.blogMaxWidth};
    padding: ${spacing.xl};
  `}

  ${screenSize.minDesktop`
    padding: ${spacing.xl} ${spacing.xxxl};
  `}
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  transform: translateY(-100%);
  padding: 0.5rem;
  background: transparent;
  border: none;

  ${screenSize.minTablet`
    padding: 0 0.5rem;
  `}
`;
