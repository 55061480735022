import NextLink from "next/link";
import * as React from "react";
import styled from "styled-components";
import { sFont } from "styles/typography";

export const StyledLink = styled.a<{ $isActive?: boolean }>`
  text-decoration: none;
  color: var(--color-text);
  font-weight: 500;
  font-size: ${sFont};
  line-height: 1.6;
  cursor: pointer;

  ${(props) => (props.$isActive ? "color: var(--color-coolAccent);" : "")}

  :hover {
    color: var(--color-coolAccent);
  }
`;

interface LinkProps {
  href?: string;
  isActive?: boolean;
  className?: string;
  children?: React.ReactNode;
}

export default function Link(props: LinkProps) {
  const { href, isActive, className, children } = props;

  return (
    <NextLink key={href} href={href || '#'} passHref legacyBehavior>
      <StyledLink $isActive={isActive} className={className}>
        {children}
      </StyledLink>
    </NextLink>
  );
}
