import * as React from "react";
import { useRouter } from "next/router";
import CtaButton from "components/ui/CtaButton";
import useSubscribeToEmail from "hooks/useSubscribeToEmail";
import { CallToActionAppearance } from "types/attheminute";

import * as Styled from "./SubscribeForm.styled";

const SUCCESS_TEXT = "Thanks for signing up for updates!";
const ALREADY_SIGNED_UP =
  "You've already signed up but we appreciate the persistence!";
const ERROR_TEXT = "We've hit a snag subscribing you. Please try again.";

interface SubscribeFormProps {
  text?: string;
  stackedLayout?: boolean;
  ctaButtonAppearance: CallToActionAppearance;
  source?: string;
  defaultEmailList?: string[];
  campaignName?: string;
  landingPageUrl?: string;
  ctaText?: string;
  referralSuccessText?: string;
  generateReferralCode?: boolean;
  align?: "left" | "center" | "right"; // Added for teaching home page
}

export default function SubscribeForm(props: SubscribeFormProps) {
  const {
    campaignName,
    landingPageUrl,
    generateReferralCode,
    ctaText,
    referralSuccessText,
    text,
    ctaButtonAppearance,
    stackedLayout,
    source,
    defaultEmailList,
    align = "center",
  } = props;

  // TODO WHEN BACK FROM DINNER::::::
  // DELETE REFERALCODE GENERATOR COMPONENT COMPLETELY.
  // DELETE CODE GENREATOR PAGE.
  // UPDATE SUBSCRIBE URL TO GENERATE CODE IF NEEDED AND RETURN IT IN THE RESPONSE

  const [email, setEmail] = React.useState("");
  const router = useRouter();
  const { subscribe, loading, data, error } = useSubscribeToEmail();
  const [uniqueUrl, setUniqueUrl] = React.useState("");
  const uniqueUrlRef: any = React.useRef();

  let referralCodeQuery = router.query.referralCode;
  let referralCode = Array.isArray(referralCodeQuery)
    ? referralCodeQuery[0]
    : referralCodeQuery;

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const response = await subscribe(email, {
      source,
      lists: defaultEmailList,
      referralCode,
      campaignName,
      generateReferralCode,
    });

    if (generateReferralCode && response?.data?.referralCode) {
      const code = response?.data?.referralCode;
      const link = `${window.location.protocol}//${window.location.host}${landingPageUrl}?referralCode=${code}`;
      setUniqueUrl(link);
      if (uniqueUrlRef.current) {
        uniqueUrlRef.current.select();
      }
    }
  };

  let statusText = "";
  let success;

  if (data && !data.error && data.result !== "error") {
    statusText = SUCCESS_TEXT;
    success = true;
  } else if (
    !error &&
    data &&
    data.result === "error" &&
    data.msg.includes("is already subscribed to list")
  ) {
    statusText = ALREADY_SIGNED_UP;
    success = true;
  } else if (error || (data && data.result === "error")) {
    statusText = ERROR_TEXT;
    success = false;
  }

  return (
    <>
      {text && <Styled.Text>{text}</Styled.Text>}

      <Styled.Form
        onSubmit={handleSubmit}
        $stackedLayout={stackedLayout}
        $align={align}
      >
        <Styled.Input
          placeholder="Email address"
          name="email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          disabled={loading}
          $stackedLayout={stackedLayout}
          required
        />
        <Styled.UnstyledButton
          aria-label="Subscribe to mailing list"
          type="submit"
          disabled={loading}
        >
          <CtaButton
            colourName={ctaButtonAppearance.colourName}
            appearance={ctaButtonAppearance.appearance}
            aria-label={ctaButtonAppearance.text}
            disabled={loading}
          >
            {ctaText || ctaButtonAppearance.text}
          </CtaButton>
        </Styled.UnstyledButton>
      </Styled.Form>
      {!generateReferralCode ? (
        <Styled.StatusText $success={success}>{statusText}</Styled.StatusText>
      ) : null}

      {!!uniqueUrl ? (
        <>
          {referralSuccessText ? (
            <Styled.ReferralText>{referralSuccessText}</Styled.ReferralText>
          ) : null}
          <Styled.UniqueUrlInput
            ref={uniqueUrlRef}
            name="unique-url"
            type="text"
            value={uniqueUrl}
            readOnly
          />
        </>
      ) : null}
    </>
  );
}
