import ConditionallyRender from 'components/ui/ConditionallyRender'
import DarkModeToggle from './DarkModeToggle';

export default function DarkModeToggleClientOnly(props) {
  return (
    <ConditionallyRender client>
      <DarkModeToggle {...props} />
    </ConditionallyRender>
  );
}
