// Burger.styled.js
import styled from 'styled-components';

export const Burger = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 100010;

  &:focus {
    outline: none;
  }
`;

export const LineContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  width: 24px;
  height: 24px;

  transition: opacity 0.2s ease-in-out;

  > div {
    width: 100%;
    height: 3px;
    background: ${props =>
      props.isMenuOpen ? 'var(--color-background)' : 'var(--color-text)'};
    transition: all 0.2s linear;
    transform-origin: 1px;

    &:first-child {
      transform: ${props => (props.isMenuOpen ? 'rotate(45deg)' : 'rotate(0)')};
    }

    &:nth-child(2) {
      opacity: ${props => (props.isMenuOpen ? '0' : '1')};
      transform: ${props =>
        props.isMenuOpen ? 'translateX(0)' : 'translateX(0)'};
    }

    &:nth-child(3) {
      transform: ${props =>
        props.isMenuOpen ? 'rotate(-45deg)' : 'rotate(0)'};
      width: ${props => (props.isMenuOpen ? '100%' : '70%')};
    }
  }
`;
