import * as React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './Burger.styled';

function Burger(props) {
  const { toggleMenu, isMenuOpen } = props;
  return (
    <Styled.Burger onClick={toggleMenu} aria-label="Toggle Menu">
      <Styled.LineContainer isMenuOpen={isMenuOpen}>
        <div />
        <div />
        <div />
      </Styled.LineContainer>
    </Styled.Burger>
  );
}

Burger.propTypes = {
  toggleMenu: PropTypes.func,
  isMenuOpen: PropTypes.bool,
};

export default Burger;
