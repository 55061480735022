import * as React from 'react';
import PropTypes from 'prop-types';

import useSliderControls from './useSliderControls';

/**
 * Page component which register's itself with Slider and then renders
 * children if it's the active page.
 */
export default function Page(props) {
  const { uniqueName, children } = props;
  const { activePage, registerPage, deregisterPage } = useSliderControls();

  // Let slider know that this page exists and should care about it
  React.useEffect(() => {
    registerPage(uniqueName);

    return () => deregisterPage(uniqueName);
  }, [uniqueName, registerPage, deregisterPage]);

  // Not the active page so hide this page
  if (uniqueName !== activePage) return null;

  // Current active page so return the children
  return children;
}

Page.propTypes = {
  uniqueName: PropTypes.string.isRequired,
  children: PropTypes.node,
};
