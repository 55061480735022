import * as React from "react";
import { useSpring, useTrail, animated } from "react-spring";
import { ThemeContext } from "components/hocs/ThemeProvider";
import colours from "styles/colours";

import * as Styled from "./DarkModeToggle.styled";

const NUM_SUN_RAYS = 8;

const DEGREES_PER_RAY = 360 / NUM_SUN_RAYS;

export default function DarkModeToggle(props) {
  const { invertColours } = props;
  const { colorMode, setColorMode } = React.useContext(ThemeContext);

  const isDark = colorMode === "dark";

  let backgroundColor;
  if (isDark && !invertColours) {
    backgroundColor = colours.light.colours.background;
  } else if (isDark && invertColours) {
    backgroundColor = colours.light.colours.text;
  } else if (!isDark && !invertColours) {
    backgroundColor = colours.light.colours.text;
  } else {
    backgroundColor = colours.light.colours.background;
  }

  const animatedMoonOrSunProps = useSpring({
    background: backgroundColor,
    transform: isDark ? "scale(1)" : "scale(0.5)",
    from: {
      background: invertColours
        ? colours.light.colours.light
        : colours.light.colours.light,
      transform: "scale(0.5)",
    },
  });
  const AnimatedMoonOrSun = animated(Styled.MoonOrSun);

  const animatedMoonCutAwayProps = useSpring({
    left: isDark ? "50%" : "150%",
    from: { left: "150%" },
  });
  const AnimatedMoonCutAway = animated(Styled.MoonCutAway);

  const trail = useTrail(NUM_SUN_RAYS, {
    number: isDark ? 1 : 50,
    background: invertColours ? "var(--color-background)" : "var(--color-text)",
    from: {
      number: 50,
      background: invertColours
        ? "var(--color-text)"
        : "var(--color-background)",
    },
    config: {
      velocity: 1,
      tension: 300,
      mass: 0.6,
      friction: 22,
    },
  });
  const AnimatedSunRay = animated(Styled.SunRay);

  if (!colorMode) {
    return null;
  }

  return (
    <Styled.DarkModeToggle isDark={isDark}>
      <AnimatedMoonOrSun style={animatedMoonOrSunProps} isDark={isDark} />
      <AnimatedMoonCutAway
        invertColours={invertColours}
        style={animatedMoonCutAwayProps}
      />
      <Styled.Input
        name="dark-mode-toggle"
        type="checkbox"
        aria-label={`Toggle ${colorMode === "dark" ? "light" : "dark"} mode`}
        value={isDark}
        onChange={(ev) => {
          setColorMode(ev.target.checked ? "dark" : "light");
        }}
        checked={colorMode === "dark"}
      />
      {trail.map((animatedSunRayProps, idx) => {
        const angleDegrees = idx * DEGREES_PER_RAY;
        const angleRadians = angleDegrees * (Math.PI / 180);

        const x = 50 * Math.cos(angleRadians) + 50;
        const y = 50 * Math.sin(angleRadians) + 50;

        const translateX = -x;
        const translateY = -y;

        return (
          <AnimatedSunRay
            key={idx}
            style={{
              left: animatedSunRayProps.number.to(
                (n) => `${n * Math.cos(angleRadians) + 50}%`
              ),
              top: animatedSunRayProps.number.to(
                (n) => `${n * Math.sin(angleRadians) + 50}%`
              ),
              transform: `translate(${translateX}%, ${translateY}%) rotate(${
                angleDegrees + 90
              }deg)`,
              background: animatedSunRayProps.background,
            }}
          />
        );
      })}
    </Styled.DarkModeToggle>
  );
}
