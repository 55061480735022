import styled, { createGlobalStyle } from "styled-components";
import spacing from "styles/spacing";
import NextImage from "next/legacy/image";
import screenSize from "styles/mediaQueries";
import DefaultMenuLink from "../Link";
import { paddedContainerStyles } from "../Layout";
import { headerHeight, headerHeightDesktop } from 'components/ui/Layout';

export const Header = styled.nav`
  ${paddedContainerStyles}

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 5;
  background: var(--color-background);
  height: ${headerHeight};

  ${screenSize.minDesktop`
    height: ${headerHeightDesktop};
  `}
`;

export const MobileNav = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  ${screenSize.minTablet`
    display: none;
  `}
`;

export const DesktopNav = styled.div`
  display: none;

  ${screenSize.minTablet`
    display: flex;
    width: 100%;
    justify-content: space-between;
  `}
`;

export const Content = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  display: flex;

  justify-content: space-between;
  align-items: center;
  padding: 0;
  height: 100%;
`;

export const Logo = styled(NextImage)`
  height: 100%;
  background: var(--color-light);
  border-radius: 100%;
`;

export const HomeLink = styled.a`
  text-decoration: none;
  margin-right: ${spacing.lg};
  position: relative;
`;

export const MenuLeft = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
`;

export const MenuLink = styled(DefaultMenuLink)`
  margin-right: ${spacing.md};
`;

export const MenuRight = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ToggleContainer = styled.div`
  height: 1.5rem;
  width: 1.5rem;
  margin-left: 0.75rem;
`;

export const SocialIcons = styled.div`
  text-align: center;
`;

export const IconLink = styled.a`
  margin-right: ${spacing.xs};
  :last-child {
    margin-right: 0;
  }
`;

export const SocialIcon = styled.img`
  width: 1.5rem;
`;

export const LogoClass = styled.div``;

export const LogoClassStyles = createGlobalStyle`
  ${LogoClass} {
    background: var(--color-light);
    border-radius: 100%;
  }
`;
