import * as React from "react";
import Link from "next/link";
import DarkModeToggle from "components/ui/DarkModeToggle";
import { TextH3 } from "styles/typography";
import NextImage from "next/legacy/image";
import NextLink from "next/link";
import Slider from "components/ui/Slider";
import { categoryTree } from "components/pages/Shop/categoryTree";
import useSliderControls from "components/ui/Slider/useSliderControls";
import { useSubjectTree } from "components/hocs/SubjectTreeProvider";
import * as NavStyled from "../Navigation.styled";
import * as Styled from "./TeachingMenuContent.styled";
import { ThemeContext } from "components/hocs/ThemeProvider";

interface TeachingMenuContentProps {
  isMenuOpen?: boolean;
  toggleMenu: () => void;
}

function AutoSwitchToMain(props: TeachingMenuContentProps) {
  const { isMenuOpen } = props;
  const { setActivePage } = useSliderControls();

  React.useEffect(() => {
    if (!isMenuOpen) {
      setActivePage("main");
    }
  }, [isMenuOpen]);

  return null;
}

function Categories() {
  const { setActivePage } = useSliderControls();
  const subjectTree = useSubjectTree();

  return (
    <>
      <Styled.MenuButton
        style={{ marginTop: "1.25rem", marginBottom: "0" }}
        onClick={() => {
          setActivePage("main");
        }}
      >
        {"<"}
        <span style={{ marginLeft: "0.625rem" }}>Categories</span>
      </Styled.MenuButton>
      {subjectTree && subjectTree.length > 0
        ? categoryTree(subjectTree, true)
        : []}
    </>
  );
}

function Main() {
  const { setActivePage } = useSliderControls();
  return (
    <>
      <Styled.LinkGroup style={{ marginTop: "1.25rem" }}>
        <Link href={"/teaching/shop"} passHref legacyBehavior>
          <Styled.MenuItemLink>Shop & Resources</Styled.MenuItemLink>
        </Link>
        <Link href={"/teaching/blog"} passHref legacyBehavior>
          <Styled.MenuItemLink>Blog</Styled.MenuItemLink>
        </Link>
        <Link href={"/teaching/guide"} passHref legacyBehavior>
          <Styled.MenuItemLink>Guides</Styled.MenuItemLink>
        </Link>
        <Link href={"/teaching/reference"} passHref legacyBehavior>
          <Styled.MenuItemLink>Reference</Styled.MenuItemLink>
        </Link>
        <Link href={"/teaching/tools"} passHref legacyBehavior>
          <Styled.MenuItemLink>Tools</Styled.MenuItemLink>
        </Link>
        <Link href={"/teaching/faq"} passHref legacyBehavior>
          <Styled.MenuItemLink>FAQ</Styled.MenuItemLink>
        </Link>
        <Link href={"/teaching/about"} passHref legacyBehavior>
          <Styled.MenuItemLink>About</Styled.MenuItemLink>
        </Link>
      </Styled.LinkGroup>

      <Styled.Separator />

      <Styled.MenuButton
        onClick={() => {
          setActivePage("categories");
        }}
      >
        <span style={{ marginRight: "0.625rem" }}>Categories</span> {">"}
      </Styled.MenuButton>
    </>
  );
}

export default function TeachingMenuContent(props: TeachingMenuContentProps) {
  const { toggleMenu } = props;

  const { colorMode } = React.useContext(ThemeContext);
  const isDark = colorMode === "dark";

  return (
    <Styled.TeachingMenu>
      <Styled.Header>
        <NextLink href="/teaching" legacyBehavior>
          <NextImage
            src={
              isDark
                ? "/images/atm_logo_teaching_dark.png"
                : "/images/atm_logo_teaching.png"
            }
            alt="attheminute"
            layout="intrinsic"
            width={115}
            height={50}
            unoptimized
          />
        </NextLink>

        <Styled.ToggleContainer>
          <DarkModeToggle />
        </Styled.ToggleContainer>
      </Styled.Header>
      <Styled.Content>
        <Slider initialPage="main">
          <AutoSwitchToMain {...props} />
          <Slider.Page uniqueName="main">
            <Main />
          </Slider.Page>
          <Slider.Page uniqueName="categories">
            <Categories />
          </Slider.Page>
        </Slider>
      </Styled.Content>

      <Styled.Footer>
        <div>
          <NavStyled.IconLink
            href="https://www.facebook.com/atm.teaching"
            target="_blank"
            rel="noreferrer"
          >
            <NavStyled.SocialIcon
              src={"/images/facebook.svg"}
              alt="Facebook Logo"
            />
          </NavStyled.IconLink>
          <NavStyled.IconLink
            href="https://www.instagram.com/atm.teaching/"
            target="_blank"
            rel="noreferrer"
          >
            <NavStyled.SocialIcon
              src={"/images/instagram.svg"}
              alt="Instagram Logo"
            />
          </NavStyled.IconLink>
        </div>
        <Styled.SubscribeButton
          onClick={toggleMenu}
          animate
        />
      </Styled.Footer>
    </Styled.TeachingMenu>
  );
}
