import styled from "styled-components";
import spacing from "styles/spacing";
import {
  TextSmallP as DefaultTextSmallP,
  fontFamilyBrand,
} from "styles/typography";

export const TextSmallP = styled(DefaultTextSmallP)`
  color: inherit;
`;

interface ButtonProps {
  disabled?: boolean;
  colourName?: string;
}

const Button = styled.div<ButtonProps>`
  font-family: ${fontFamilyBrand};
  text-decoration: none;
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
  min-height: 2.125rem;
  width: fit-content;
  text-align: center;
  transition: all 0.2s ease 0s;
  border-radius: 6.25rem;
  padding: ${spacing.xs} ${spacing.md} ${spacing.xs} ${spacing.md};
  margin: 0;
  color: ${({ colourName }) => `var(--color-contrast-${colourName})`};
  background: ${({ colourName, disabled }) =>
    disabled ? "var(--color-inputDisabled)" : `var(--color-${colourName})`};
  border-width: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SolidButton = styled(Button)`
  color: ${({ colourName }) => `var(--color-contrast-${colourName})`};
  background: ${(props) =>
    props.disabled
      ? "var(--color-inputDisabled)"
      : `var(--color-${props.colourName})`};
  border: 2px solid
    ${({ colourName, disabled }) =>
      disabled
        ? "var(--color-inputDisabled)"
        : `var(--color-contrast-${colourName})`};
  transition: background 0.2s;
  :hover {
    background: ${({ disabled, colourName }) =>
      disabled
        ? "var(--color-inputDisabled)"
        : `var(--color-contrast-${colourName})`};
    color: ${({ disabled, colourName }) =>
      disabled
        ? `var(--color-contrast-${colourName})`
        : `var(--color-${colourName})`};
    transition: background 0.2s;
  }
`;

export const SolidBorderless = styled(SolidButton)`
  border: none;
`;

export const OutlineButton = styled(Button)`
  color: ${({ colourName }) => `var(--color-${colourName})`};
  background: transparent;
  border: 2px solid ${({ colourName }) => `var(--color-${colourName})`};
  transition: all 0.2s;
  :hover {
    background: ${({ colourName }) => `var(--color-${colourName})`};
    color: ${({ colourName }) => `var(--color-contrast-${colourName})`};
    transition: all 0.2s;
  }
`;

export const LinkButton = styled(Button)`
  border-radius: 4px;
  color: ${({ colourName }) => `var(--color-${colourName})`};
  position: relative;
  background: transparent;
  transition: all 0.2s;
  outline: none;

  padding: 0 ${spacing.xs};

  :hover {
    background: var(--color-backgroundHover);
  }
`;

export const SquareButton = styled(Button)`
  border-radius: 4px;
  color: ${({ colourName }) => `var(--color-contrast-${colourName})`};
  background: ${(props) =>
    props.disabled
      ? "var(--color-inputDisabled)"
      : `var(--color-${props.colourName})`};

  position: relative;
  transition: all 0.2s;
  outline: none;
  padding: ${spacing.sm} ${spacing.lg};

  :hover {
    background: ${({ colourName }) => `var(--color-contrast-${colourName})`};
    background: ${({ disabled, colourName }) =>
    disabled
      ? "var(--color-inputDisabled)"
      : `var(--color-contrast-${colourName})`};

    color: ${({ colourName }) => `var(--color-${colourName})`};
    color: ${({ disabled, colourName }) =>
    disabled
      ? `var(--color-contrast-${colourName})`
      : `var(--color-${colourName})`};
  }
`;
