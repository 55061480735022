import * as React from "react";
import { addToSendgrid, SubscribeOptions } from "api/email";

const useSubscribeToEmail = () => {
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState<any>(null);
  const [error, setError] = React.useState<any>(null);

  const subscribe = async (email: string, options: SubscribeOptions) => {
    try {
      setError(null);
      setLoading(true);
      setData(null);

      const result = await addToSendgrid(email, options);

      setData(result);
      setLoading(false);

      return result;
    } catch (err) {
      setError(err);
      setLoading(false);
    }
  };
  return {
    subscribe,
    loading,
    data,
    error,
  };
};

export default useSubscribeToEmail;
