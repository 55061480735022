import * as React from "react";
import colours, { colourNames } from "styles/colours";

type ColorMode = "light" | "dark";

export type ThemeContextTypes = {
  colorMode?: ColorMode;
  setColorMode?: (newValue: ColorMode) => void;
}

export const ThemeContext = React.createContext<ThemeContextTypes>({});

function ThemeProvider({ children }: { children: React.ReactNode }) {
  const [colorMode, rawSetColorMode] = React.useState<ColorMode>("light");

  React.useEffect(() => {
    const root = window.document.documentElement;
    const initialColorValue = root.style.getPropertyValue(
      "--initial-color-mode"
    ) as ColorMode;
    rawSetColorMode(initialColorValue);
  }, []);

  const setColorMode = React.useCallback((newValue: ColorMode) => {
    const root = window.document.documentElement;
    // 1. Update React color-mode state
    rawSetColorMode(newValue);

    // 2. Update localStorage
    localStorage.setItem("color-mode", newValue);

    // 3. Update each color
    // eslint-disable-next-line no-unused-vars
    Object.entries(colourNames).forEach(([_, colourName]) => {
      root.style.setProperty(
        `--color-${colourName}`,
        newValue === "light"
          ? colours.light.colours[colourName]
          : colours.dark.colours[colourName]
      );
    });

    // eslint-disable-next-line no-unused-vars
    Object.entries(colourNames).forEach(([_, colourName]) => {
      root.style.setProperty(
        `--color-contrast-${colourName}`,
        newValue === "light"
          ? colours.light.contrasts[colourName]
          : colours.dark.contrasts[colourName]
      );
    });

    root.style.setProperty("--initial-color-mode", newValue);
  }, [rawSetColorMode]);

  const value = React.useMemo(
    () => ({ colorMode, setColorMode }),
    [colorMode, setColorMode]
  );

  return (
    <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
  );
}

export default ThemeProvider;
