import styled, { css } from "styled-components";
import screenSize from "styles/mediaQueries";
import spacing from "styles/spacing";
import layout from "styles/layout";

export const headerHeight = "3.75rem";
export const headerHeightDesktop = "3.75rem";

export const HeaderGap = styled.div`
  padding-top: ${headerHeight};

  ${screenSize.minDesktop`
    padding-top: ${headerHeightDesktop};
  `}
`;

export const mobileXPadding = spacing.md;
export const minTabletXPadding = spacing.lg;
export const minDesktopXPadding = spacing.xl;

export const paddedContainerStyles = css`
  padding: 0 ${mobileXPadding};

  ${screenSize.minTablet`
    padding: 0 ${minTabletXPadding};
  `}

  ${screenSize.minDesktop`
    padding: 0 ${minDesktopXPadding};
  `}
`;

export const PaddedContainer = styled.div`
  ${paddedContainerStyles}
`;

export const Content = styled.div<{ isBlogWidth?: boolean }>`
  margin: 0 auto;
  max-width: ${(props) =>
    props.isBlogWidth ? layout.blogMaxWidth : layout.expandedContentMaxWidth};
`;
