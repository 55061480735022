import * as React from "react";

import * as Styled from "./Menu.styled";
import DefaultMenuContent from "./DefaultMenuContent";
import TeachingMenuContent from "./TeachingMenuContent";

interface MobileMenuProps {
  isMenuOpen: boolean;
  toggleMenu: () => void;
  isTeachingMenu?: boolean;
}

export default function MobileMenu(props: MobileMenuProps) {
  const { isMenuOpen, toggleMenu, isTeachingMenu } = props;

  return (
    <>
      <Styled.Overlay $isMenuOpen={isMenuOpen} onClick={() => toggleMenu()} />
      <Styled.Menu $isMenuOpen={isMenuOpen}>
        {isTeachingMenu ? (
          <TeachingMenuContent
            isMenuOpen={isMenuOpen}
            toggleMenu={toggleMenu}
          />
        ) : (
          <DefaultMenuContent toggleMenu={toggleMenu} />
        )}
      </Styled.Menu>
    </>
  );
}
