import styled from "styled-components";
import spacing from "styles/spacing";
import { TextH2, TextSmallP } from "styles/typography";
import screenSize from "styles/mediaQueries";

export const Text = styled(TextH2)`
  max-width: 125rem;
  text-align: center;
  color: var(--color-text);
`;

export const UnstyledButton = styled.button`
  text-decoration: none;
  border: none;
  background: none;
  padding: 0;
  margin: 0;
`;

export const Form = styled.form<{
  $stackedLayout?: boolean;
  $align?: "left" | "center" | "right";
}>`
  display: flex;
  justify-content: ${(props) => {
    if (props.$align === "left") {
      return "flex-start";
    }
    if (props.$align === "center") {
      return "center";
    }
    if (props.$align === "right") {
      return "flex-end";
    }

    return "center";
  }};

  flex-direction: column;
  align-items: center;

  ${screenSize.minTablet`
    ${(props: { $stackedLayout?: boolean }) =>
      props.$stackedLayout ? "" : "flex-direction: row;"}
    align-items: center;
  `}
`;

export const Input = styled.input<{ $stackedLayout?: boolean }>`
  border: none;
  padding: 0.75rem;
  border-radius: 6px;
  margin-bottom: ${spacing.sm};
  margin-right: 0;
  border: 1px solid var(--color-inputOutline);
  width: 100%;

  background: ${(props) =>
    props.disabled ? "var(--color-inputDisabled)" : "var(--color-background)"};

  ${screenSize.minTablet`
    margin-right: ${spacing.sm};
    ${(props: { $stackedLayout?: boolean }) =>
      props.$stackedLayout
        ? `width: 100%`
        : `
        margin-right: ${spacing.sm};
        margin-bottom: 0;
      `}
  `}
`;

export const StatusText = styled(TextSmallP)<{ $success?: boolean }>`
  color: ${({ $success }) =>
    $success ? "var(--color-lightAccent)" : "var(--color-darkAccent)"};
  text-align: center;
  margin-top: ${spacing.sm};
`;

export const ReferralText = styled(TextSmallP)`
  margin-top: ${spacing.md};
`;

export const UniqueUrlInput = styled(Input)`
  margin-top: ${spacing.xs};
  width: 100%;
`;
