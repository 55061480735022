import * as React from 'react';
import PropTypes from 'prop-types';

import { SliderContext } from './useSliderControls';

import Page from './Page';

/**
 * Slider designed to show one page at a time. Can have any number of pages.
 */
export default function Slider(props) {
  const { children, initialPage } = props;

  // Ref is used for two properties we need
  // 1) its mutable so that each child can update it in the same render cycle.
  // 2) it's a way to manage state without triggering a re-render (In this case all we use it for is interally keeping track of pages)
  // More info https://www.codebeast.dev/usestate-vs-useref-re-render-or-not/
  const { current: pages } = React.useRef([]);
  const [activePage, setActivePage] = React.useState(initialPage);

  const value = React.useMemo(
    () => ({
      activePage,
      setActivePage,
      pages,
    }),
    [activePage, pages]
  );

  return (
    <SliderContext.Provider value={value}>{children}</SliderContext.Provider>
  );
}

Slider.propTypes = {
  children: PropTypes.node.isRequired,
  initialPage: PropTypes.string,
};

Slider.Page = Page;
