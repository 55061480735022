import useToggle from "hooks/useToggle";
import DarkModeToggle from "components/ui/DarkModeToggle";
import NextImage from "next/legacy/image";
import { useRouter } from "next/router";
import Link from "next/link";
import MobileMenu from "./MobileMenu";
import Burger from "./Burger";
import SubscribeButton from "./SubscribeButton";
import * as Styled from "./Navigation.styled";
import { HeaderGap } from "components/ui/Layout";

export const defaultMenuItems = [
  {
    text: "Home",
    link: "/",
    emoji: "",
    includeDesktop: false,
  },
  {
    text: "Teaching",
    link: "/teaching",
    emoji: "",
    includeDesktop: true,
  },
  {
    text: "Shop",
    link: "/teaching/shop",
    emoji: "",
    includeDesktop: true,
  },
  {
    text: "Blog",
    link: "/blog",
    emoji: "",
    includeDesktop: true,
  },
  {
    text: "About",
    link: "/about",
    emoji: "",
    includeDesktop: true,
  },
];

interface NavigationProps {
  renderMenuLeft?: any;
  renderMenuRight?: any;
  renderMenuRightMobile?: any;
  menuItems?: any[];
  hideSubscribeButton?: boolean;
  isTeachingMenu?: boolean;
}

function Navigation(props: NavigationProps) {
  const {
    renderMenuLeft,
    renderMenuRight,
    renderMenuRightMobile,
    hideSubscribeButton,
    menuItems = defaultMenuItems,
    isTeachingMenu,
  } = props;
  const router = useRouter();

  const [isMenuOpen, toggleMenu] = useToggle(false);

  return (
    <Styled.Header>
      <Styled.Content>
        {/* DESKTOP */}
        <Styled.DesktopNav>
          <Styled.MenuLeft>
            {renderMenuLeft ? (
              renderMenuLeft()
            ) : (
              <>
                <Styled.LogoClassStyles />
                <Link href="/" passHref legacyBehavior>
                  <Styled.HomeLink>
                    <NextImage
                      src="/images/atm_logo.png"
                      alt="attheminute"
                      layout="fixed"
                      width={60}
                      height={60}
                      unoptimized
                      className={Styled.LogoClass.styledComponentId}
                    />
                  </Styled.HomeLink>
                </Link>
              </>
            )}
          </Styled.MenuLeft>
          <Styled.MenuRight>
            {menuItems &&
              menuItems
                .filter((menuItem) => menuItem.includeDesktop)
                .map((menuItem) => (
                  <Styled.MenuLink
                    key={menuItem.link}
                    href={menuItem.link}
                    isActive={router.pathname === menuItem.link}
                  >
                    {menuItem.text}
                  </Styled.MenuLink>
                ))}
            {hideSubscribeButton ? null : <SubscribeButton />}

            {/* <Styled.SocialIcons>
                <Styled.IconLink
                  href="https://www.facebook.com/atm.teaching"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Styled.SocialIcon
                    src="/images/facebook.svg"
                    alt="Facebook Logo"
                    width={24}
                    height={24}
                  />
                </Styled.IconLink>
                <Styled.IconLink
                  href="https://www.instagram.com/atm.teaching/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Styled.SocialIcon
                    src="/images/instagram.svg"
                    alt="Instagram Logo"
                    width={24}
                    height={24}
                  />
                </Styled.IconLink>
              </Styled.SocialIcons> */}
            {renderMenuRight ? renderMenuRight() : null}
            <Styled.ToggleContainer>
              <DarkModeToggle />
            </Styled.ToggleContainer>
          </Styled.MenuRight>
        </Styled.DesktopNav>

        {/* MOBILE */}
        <Styled.MobileNav>
          <MobileMenu
            isMenuOpen={isMenuOpen}
            toggleMenu={toggleMenu}
            isTeachingMenu={isTeachingMenu}
          />
          {renderMenuLeft ? (
            renderMenuLeft()
          ) : (
            <>
              <Styled.LogoClassStyles />
              <Link href="/" passHref legacyBehavior>
                <Styled.HomeLink>
                  <NextImage
                    src="/images/atm_logo.png"
                    alt="attheminute"
                    layout="fixed"
                    width={40}
                    height={40}
                    unoptimized
                    className={Styled.LogoClass.styledComponentId}
                  />
                </Styled.HomeLink>
              </Link>
            </>
          )}
          <Styled.MenuRight>
            {renderMenuRightMobile ? renderMenuRightMobile() : null}
            <Burger isMenuOpen={isMenuOpen} toggleMenu={toggleMenu} />
          </Styled.MenuRight>
        </Styled.MobileNav>
      </Styled.Content>
    </Styled.Header>
  );
}

export default Navigation;

export function NavigationGap() {
  return <HeaderGap />;
}
