import { ValueOf } from "types/lib";

const baseColours = {
  bunker: "#0E141B",
  mirage: "#19232A",

  jumbo: "#828089",
  mischka: "#d3d4dc",
  paleGrey: "#e3e7ed",
  white: "#ffffff",

  silverSand: "#c9cccf",

  tallPoppy: "#be302a", // Used in some storyblok stories so can't be fully deleted

  jordyBlue: "#8BB4F1",

  cornflowerBlue: "#4285F4",
  something: "#2879A4",
  blueZodiac: "#123663",
  mountainMeadow: "#1B998B",

  springGreen: "#00e676",
  torchRed: "#ff0256",

  verdigris: "#585635",
  indianKhaki: "#BFAF8F",
};

export const hslCodes = {
  // mainBrandColour: { h: 56, s: 96, l: 54 }, // hsla(56, 96%, 54%, 1) #FAEB1A
  // mainBrandColour: { h: 46, s: 100, l: 50 }, // hsla(56, 96%, 54%, 1) #FEC300
  mainBrandColour: { h: 48, s: 100, l: 50 }, // #FFCC00
  warmAccent: { h: 348, s: 100, l: 50 }, // #FF0033
  coolAccent: { h: 192, s: 100, l: 25 }, // #006680

  darkAccent: { h: 198, s: 63, l: 38 }, // hsla(198, 63%, 38%, 1)
  lightAccent: { h: 190, s: 27, l: 62 }, // hsla(190, 27%, 61%, 1)
  lightShades: { h: 180, s: 6, l: 97 }, // hsla(180, 6%, 97%, 1)
  darkShades: { h: 230, s: 41, l: 28 }, // hsla(224, 16%, 33%, 1)
};

export const colourNames = {
  MAIN_BRAND_COLOUR: "mainBrandColour",

  WARM_ACCENT: "warmAccent",
  COOL_ACCENT: "coolAccent",

  LIGHT_ACCENT: "lightAccent",
  LIGHT_SHADES: "lightShades",
  DARK_ACCENT: "darkAccent",
  DARK_SHADES: "darkShades",

  MAIN_BRAND_COLOUR_MUTED: "mainBrandColourMuted",
  LIGHT_ACCENT_MUTED: "lightAccentMuted",
  LIGHT_SHADES_MUTED: "lightShadesMuted",
  DARK_ACCENT_MUTED: "darkAccentMuted",
  DARK_SHADES_MUTED: "darkShadesMuted",

  DARK: "dark",
  LIGHT: "light",

  TEXT: "text",
  BACKGROUND: "background",
  BACKGROUND_HOVER: "backgroundHover",
  LABEL: "label",

  SUCCESS: "success",
  FAIL: "fail",
  PENDING: "pending",

  INPUT_OUTLINE: "inputOutline",
  INPUT_DISABLED: "inputDisabled",
  MODAL_OVERLAY: "modalOverlay",
  DARK_OVERLAY: "darkOverlay",
  LIGHT_OVERLAY: "lightOverlay",

  JORDY_BLUE: "jordyBlue",
  TORCH_RED: "torchRed",
  BLUE_ZODIAC: "blueZodiac",
  MOUNTAIN_MEADOW: "mountainMeadow",
  TALL_POPPY: "tallPoppy",
  CORN_FLOWER_BLUE: "cornflowerBlue",
  SILVER_SAND: "silverSand",

  INDIAN_KHAKI: "indianKhaki",
  VERDIGRIS: "verdigris",
} as const;

export type ColourName = ValueOf<typeof colourNames>;
type HSLColours = keyof typeof hslCodes;

const getHSL = (colourName: HSLColours, transparency?: number) =>
  `hsla(${hslCodes[colourName].h}, ${hslCodes[colourName].s}%, ${
    hslCodes[colourName].l
  }%, ${transparency ? transparency : 1})`;

const lightColours = {
  [colourNames.MAIN_BRAND_COLOUR]: getHSL("mainBrandColour"),
  [colourNames.WARM_ACCENT]: getHSL("warmAccent"),
  [colourNames.COOL_ACCENT]: getHSL("coolAccent"),
  [colourNames.LIGHT_ACCENT]: getHSL("lightAccent"),
  [colourNames.LIGHT_SHADES]: getHSL("lightShades"),
  [colourNames.DARK_ACCENT]: getHSL("darkAccent"),
  [colourNames.DARK_SHADES]: getHSL("darkShades"),

  [colourNames.MAIN_BRAND_COLOUR_MUTED]: getHSL("mainBrandColour", 0.2),
  [colourNames.LIGHT_ACCENT_MUTED]: getHSL("lightAccent", 0.2),
  [colourNames.LIGHT_SHADES_MUTED]: getHSL("lightShades", 0.2),
  [colourNames.DARK_ACCENT_MUTED]: getHSL("darkAccent", 0.2),
  [colourNames.DARK_SHADES_MUTED]: getHSL("darkShades", 0.2),

  [colourNames.DARK]: baseColours.mirage,
  [colourNames.LIGHT]: baseColours.white,

  [colourNames.TEXT]: baseColours.mirage,
  [colourNames.BACKGROUND]: baseColours.white,
  [colourNames.BACKGROUND_HOVER]: baseColours.paleGrey,
  [colourNames.LABEL]: baseColours.jumbo,

  [colourNames.INPUT_OUTLINE]: baseColours.mischka,
  [colourNames.INPUT_DISABLED]: baseColours.paleGrey,
  [colourNames.MODAL_OVERLAY]: "rgba(0, 0, 0, 0.4)",
  [colourNames.DARK_OVERLAY]: "rgba(0, 0, 0, 0.5)",
  [colourNames.LIGHT_OVERLAY]: "rgba(255, 255, 255, 0.4)",

  [colourNames.TORCH_RED]: baseColours.torchRed,
  [colourNames.BLUE_ZODIAC]: baseColours.blueZodiac,
  [colourNames.JORDY_BLUE]: baseColours.jordyBlue,
  [colourNames.MOUNTAIN_MEADOW]: baseColours.mountainMeadow,
  [colourNames.TALL_POPPY]: baseColours.tallPoppy,
  [colourNames.CORN_FLOWER_BLUE]: baseColours.cornflowerBlue,
  [colourNames.SILVER_SAND]: baseColours.silverSand,
  [colourNames.INDIAN_KHAKI]: baseColours.indianKhaki,
  [colourNames.VERDIGRIS]: baseColours.verdigris,

  [colourNames.SUCCESS]: baseColours.springGreen,
  [colourNames.FAIL]: baseColours.torchRed,
  [colourNames.PENDING]: baseColours.jordyBlue,
};

export const lightContrasts = {
  [colourNames.MAIN_BRAND_COLOUR]: lightColours[colourNames.DARK],

  [colourNames.WARM_ACCENT]: lightColours[colourNames.LIGHT],
  [colourNames.COOL_ACCENT]: lightColours[colourNames.LIGHT],

  [colourNames.LIGHT_ACCENT]: lightColours[colourNames.DARK],
  [colourNames.LIGHT_SHADES]: lightColours[colourNames.DARK],
  [colourNames.DARK_ACCENT]: lightColours[colourNames.LIGHT],
  [colourNames.DARK_SHADES]: lightColours[colourNames.LIGHT],

  [colourNames.MAIN_BRAND_COLOUR_MUTED]: lightColours[colourNames.DARK], // New - added to appease typescript
  [colourNames.LIGHT_ACCENT_MUTED]: lightColours[colourNames.DARK], // New - added to appease typescript
  [colourNames.LIGHT_SHADES_MUTED]: lightColours[colourNames.DARK], // New - added to appease typescript
  [colourNames.DARK_ACCENT_MUTED]: lightColours[colourNames.LIGHT], // New - added to appease typescript
  [colourNames.DARK_SHADES_MUTED]: lightColours[colourNames.LIGHT], // New - added to appease typescript

  [colourNames.DARK]: lightColours[colourNames.LIGHT],
  [colourNames.LIGHT]: lightColours[colourNames.DARK],

  [colourNames.TEXT]: lightColours[colourNames.BACKGROUND],
  [colourNames.BACKGROUND]: lightColours[colourNames.TEXT],
  [colourNames.BACKGROUND_HOVER]: lightColours[colourNames.TEXT], // New - added to appease typescript
  [colourNames.LABEL]: baseColours.jumbo,

  [colourNames.INPUT_OUTLINE]: lightColours[colourNames.DARK], // New - added to appease typescript
  [colourNames.INPUT_DISABLED]: lightColours[colourNames.DARK], // New - added to appease typescript
  [colourNames.MODAL_OVERLAY]: lightColours[colourNames.LIGHT], // New - added to appease typescript
  [colourNames.DARK_OVERLAY]: lightColours[colourNames.LIGHT], // New - added to appease typescript
  [colourNames.LIGHT_OVERLAY]: lightColours[colourNames.DARK], // New - added to appease typescript

  [colourNames.BLUE_ZODIAC]: lightColours[colourNames.LIGHT],
  [colourNames.MOUNTAIN_MEADOW]: lightColours[colourNames.LIGHT],
  [colourNames.TALL_POPPY]: lightColours[colourNames.LIGHT],
  [colourNames.CORN_FLOWER_BLUE]: lightColours[colourNames.LIGHT],
  [colourNames.TORCH_RED]: lightColours[colourNames.LIGHT],
  [colourNames.JORDY_BLUE]: lightColours[colourNames.LIGHT], // New - added to appease typescript
  [colourNames.SILVER_SAND]: lightColours[colourNames.DARK],
  [colourNames.INDIAN_KHAKI]: lightColours[colourNames.DARK],
  [colourNames.VERDIGRIS]: lightColours[colourNames.DARK],

  [colourNames.SUCCESS]: lightColours[colourNames.LIGHT],
  [colourNames.FAIL]: lightColours[colourNames.DARK],
  [colourNames.PENDING]: lightColours[colourNames.LIGHT],
};

const darkColours = {
  [colourNames.MAIN_BRAND_COLOUR]: getHSL("mainBrandColour"),

  [colourNames.WARM_ACCENT]: getHSL("warmAccent"),
  [colourNames.COOL_ACCENT]: getHSL("coolAccent"),

  [colourNames.LIGHT_ACCENT]: getHSL("darkAccent"),
  [colourNames.LIGHT_SHADES]: baseColours.mirage,
  [colourNames.DARK_ACCENT]: getHSL("lightAccent"),
  [colourNames.DARK_SHADES]: getHSL("lightShades"),

  [colourNames.MAIN_BRAND_COLOUR_MUTED]: getHSL("mainBrandColour", 0.2),
  [colourNames.LIGHT_ACCENT_MUTED]: getHSL("lightAccent", 0.2),
  [colourNames.LIGHT_SHADES_MUTED]: getHSL("lightShades", 0.2),
  [colourNames.DARK_ACCENT_MUTED]: getHSL("lightAccent", 0.2),
  [colourNames.DARK_SHADES_MUTED]: getHSL("lightShades", 0.2),

  [colourNames.DARK]: baseColours.mirage,
  [colourNames.LIGHT]: baseColours.white,

  [colourNames.TEXT]: baseColours.silverSand,
  [colourNames.BACKGROUND]: baseColours.bunker,
  [colourNames.BACKGROUND_HOVER]: baseColours.mirage,
  [colourNames.LABEL]: baseColours.jumbo,

  [colourNames.INPUT_OUTLINE]: baseColours.mischka,
  [colourNames.INPUT_DISABLED]: baseColours.paleGrey,
  [colourNames.MODAL_OVERLAY]: "rgba(255, 255, 255, 0.4)",
  [colourNames.DARK_OVERLAY]: "rgba(0, 0, 0, 0.5)",
  [colourNames.LIGHT_OVERLAY]: "rgba(255, 255, 255, 0.4)",

  [colourNames.TORCH_RED]: baseColours.torchRed,
  [colourNames.BLUE_ZODIAC]: baseColours.silverSand,
  [colourNames.JORDY_BLUE]: baseColours.jordyBlue,
  [colourNames.MOUNTAIN_MEADOW]: baseColours.mountainMeadow,
  [colourNames.TALL_POPPY]: baseColours.tallPoppy,
  [colourNames.CORN_FLOWER_BLUE]: baseColours.tallPoppy,
  [colourNames.SILVER_SAND]: baseColours.silverSand,
  [colourNames.INDIAN_KHAKI]: baseColours.indianKhaki,
  [colourNames.VERDIGRIS]: baseColours.verdigris,

  [colourNames.SUCCESS]: baseColours.springGreen,
  [colourNames.FAIL]: baseColours.torchRed,
  [colourNames.PENDING]: baseColours.jordyBlue,
};

export const darkContrasts = {
  [colourNames.MAIN_BRAND_COLOUR]: darkColours[colourNames.DARK],
  [colourNames.WARM_ACCENT]: darkColours[colourNames.LIGHT],
  [colourNames.COOL_ACCENT]: darkColours[colourNames.LIGHT],

  [colourNames.LIGHT_ACCENT]: darkColours[colourNames.DARK],
  [colourNames.LIGHT_SHADES]: darkColours[colourNames.TEXT],
  [colourNames.DARK_ACCENT]: darkColours[colourNames.LIGHT],
  [colourNames.DARK_SHADES]: darkColours[colourNames.LIGHT],

  [colourNames.MAIN_BRAND_COLOUR_MUTED]: darkColours[colourNames.DARK], // New - added to appease typescript
  [colourNames.LIGHT_ACCENT_MUTED]: darkColours[colourNames.DARK], // New - added to appease typescript
  [colourNames.LIGHT_SHADES_MUTED]: darkColours[colourNames.DARK], // New - added to appease typescript
  [colourNames.DARK_ACCENT_MUTED]: darkColours[colourNames.LIGHT], // New - added to appease typescript
  [colourNames.DARK_SHADES_MUTED]: darkColours[colourNames.LIGHT], // New - added to appease typescript

  [colourNames.DARK]: darkColours[colourNames.LIGHT],
  [colourNames.LIGHT]: darkColours[colourNames.DARK],

  [colourNames.TEXT]: darkColours[colourNames.BACKGROUND],
  [colourNames.BACKGROUND]: darkColours[colourNames.TEXT],
  [colourNames.BACKGROUND_HOVER]: darkColours[colourNames.TEXT], // New - added to appease typescript
  [colourNames.LABEL]: baseColours.jumbo,

  [colourNames.INPUT_OUTLINE]: darkColours[colourNames.DARK], // New - added to appease typescript
  [colourNames.INPUT_DISABLED]: darkColours[colourNames.DARK], // New - added to appease typescript
  [colourNames.MODAL_OVERLAY]: darkColours[colourNames.LIGHT], // New - added to appease typescript
  [colourNames.DARK_OVERLAY]: darkColours[colourNames.LIGHT], // New - added to appease typescript
  [colourNames.LIGHT_OVERLAY]: darkColours[colourNames.DARK], // New - added to appease typescript

  [colourNames.BLUE_ZODIAC]: darkColours[colourNames.LIGHT],
  [colourNames.MOUNTAIN_MEADOW]: darkColours[colourNames.LIGHT],
  [colourNames.TORCH_RED]: darkColours[colourNames.LIGHT], // New - added to appease typescript
  [colourNames.JORDY_BLUE]: darkColours[colourNames.LIGHT], // New - added to appease typescript
  [colourNames.TALL_POPPY]: darkColours[colourNames.LIGHT], // New - added to appease typescript
  [colourNames.CORN_FLOWER_BLUE]: darkColours[colourNames.LIGHT], // New - added to appease typescript
  [colourNames.SILVER_SAND]: darkColours[colourNames.LIGHT], // New - added to appease typescript
  [colourNames.INDIAN_KHAKI]: lightColours[colourNames.LIGHT],
  [colourNames.VERDIGRIS]: lightColours[colourNames.LIGHT],

  [colourNames.SUCCESS]: darkColours[colourNames.LIGHT],
  [colourNames.FAIL]: darkColours[colourNames.DARK],
  [colourNames.PENDING]: darkColours[colourNames.LIGHT],
};

const colours = {
  light: {
    colours: lightColours,
    contrasts: lightContrasts,
  },
  dark: {
    colours: darkColours,
    contrasts: darkContrasts,
  },
};

export default colours;
