import styled from "styled-components";
import * as DefaultSeparator from "@radix-ui/react-separator";
import screenSize from "styles/mediaQueries";
import { mFont, fontFamilyBrand, fontFamilyLabel } from "styles/typography";
import spacing from "styles/spacing";
import DefaultSubscribeButton from "../SubscribeButton";

const menuItemStyles = `
  color: var(--color-text);
  font-size: ${mFont};
  line-height: 1;
  font-weight: 600;
  font-family: ${fontFamilyLabel};

  :not(:last-child) {
    margin-bottom: ${spacing.md};
  }
`;

export const MenuItemLink = styled.a`
  ${menuItemStyles}
`;

export const MenuButton = styled.button`
  margin-top: 0;
  margin-left: 0;
  margin-right: 0;
  padding: 0;
  border: none;
  background: transparent;
  ${menuItemStyles}
`;

export const SubscribeButton = styled(DefaultSubscribeButton)`
  margin: 1.25rem auto;
`;

export const ToggleContainer = styled.div`
  height: 1.5rem;
  width: 1.5rem;
`;

export const TeachingMenu = styled.div`
  width: 100%;
  height: 100%;
  background: var(--color-background);
  padding: 1.25rem 2.5rem;
  display: flex;
  flex-direction: column;
`;

export const LinkGroup = styled.div`
  display: flex;
  flex-direction: column;
  a {
    color: var(--color-text);
    text-decoration: none;
    transition: color 0.2s linear;

    ${screenSize.minTablet`
    font-size: 1.25rem;
  `}
  }
`;

export const SpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Content = styled.div`
  flex: 1;
  overflow-y: scroll;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 0;
  min-height: 0;
`;

export const Footer = styled.div`
  flex-grow: 0;
  min-height: 0;
`;

export const Separator = styled(DefaultSeparator.Root)`
  height: 1px;
  width: 100%;
  background-color: var(--color-inputOutline);
  margin: 1.25rem 0;
`;
