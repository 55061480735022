import styled from 'styled-components';

export const DarkModeToggle = styled.label`
  cursor: pointer;
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

export const MoonOrSun = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 100%;
  z-index: 2;
`;

export const MoonCutAway = styled.div<{ invertColours: boolean }>`
  position: absolute;
  width: 120%;
  height: 120%;
  background: ${({ invertColours }) =>
    invertColours ? 'var(--color-text)' : 'var(--color-background)'};
  top: 50%;
  left: 50%;
  transform: translate(-20%, -75%);
  border-radius: 100%;
  z-index: 3;
`;

export const Input = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`;

export const SunRay = styled.div`
  position: absolute;
  width: 12%;
  height: 12%;
  border-radius: 100%;
  z-index: 1;
`;
