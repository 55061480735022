import * as React from "react";
import Modal from "react-modal";
import PropTypes from "prop-types";
import SubscribeForm from "components/sections/EmailSubscribe/SubscribeForm";
import { useSpring, animated } from "react-spring";
import { Wrapper, CloseButton } from "./SubscribeModal.styled";
import { useRouter } from "next/router";
import Cross24 from "../../../../public/images/icons/Cross24";

const subscribeFormProps = {
  text: "Sign up for the latest content and updates!",
  ctaButtonAppearance: {
    colourName: "mainBrandColour",
    appearance: "solid",
    text: "Subscribe",
  },
  theme: "light",
};

const modalStyles = {
  overlay: {
    zIndex: 19,
    backgroundColor: "var(--color-modalOverlay)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  content: {
    position: "relative",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    border: "none",
    overflow: "none",
    WebkitOverflowScrolling: "touch",
    outline: "none",
    padding: 0,
    background: "none",
  },
};

export default function SubscribeModal(props) {
  const { isOpen, onClose, dataTestIdContent, dataTestidCloseModal, animate } =
    props;

  const animationProps = useSpring({
    reverse: !isOpen,
    from: { transform: "translateX(100%)", opacity: 0 },
    to: { transform: "translateX(0)", opacity: 1 },
  });
  const AnimatedWrapper = React.useMemo(
    () => (animate ? animated(Wrapper) : Wrapper),
    [animate]
  );

  const router = useRouter();

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Subscribe to email modal"
      style={modalStyles}
    >
      <AnimatedWrapper data-testid={dataTestIdContent} style={animationProps}>
        <CloseButton
          aria-label="Close Button"
          onClick={onClose}
          data-testid={dataTestidCloseModal}
        >
          <Cross24 colour="white" />
        </CloseButton>
        <SubscribeForm
          {...subscribeFormProps}
          source={`modal-generic-${router.asPath}`}
        />
      </AnimatedWrapper>
    </Modal>
  );
}

SubscribeModal.defaultProps = {
  dataTestIdContent: "subscribe-modal-content",
  dataTestidCloseModal: "subscribe-modal-close",
  animate: false,
};

SubscribeModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  animate: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  dataTestIdContent: PropTypes.string,
  dataTestidCloseModal: PropTypes.string,
};
