import * as React from "react";
import { Button } from "@/components/ui/button";
import SubscribeModal from "../SubscribeModal";
import useToggle from "hooks/useToggle";

interface SubscribeButtonProps extends React.ComponentProps<typeof Button> {
  text?: React.ReactNode;
  animate?: boolean;
}

export default function SubscribeButton(props: SubscribeButtonProps) {
  const { text = "Subscribe :)", onClick, animate, ...rest } = props;
  const [isSubscribeModalOpen, toggleSubscribeModal] = useToggle(false);

  return (
    <>
      <Button
        {...rest}
        onClick={(e) => {
          toggleSubscribeModal();
          if (onClick) onClick(e);
        }}
      >
        {text}
      </Button>
      <SubscribeModal
        animate={animate}
        isOpen={isSubscribeModalOpen}
        onClose={toggleSubscribeModal}
      />
    </>
  );
}
