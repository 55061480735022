import styled from "styled-components";
import screenSize from "styles/mediaQueries";
import { lFont, fontFamilyBrand } from "styles/typography";
import spacing from "styles/spacing";
import DefaultSubscribeButton from "../SubscribeButton";

export const Overlay = styled.div<{ $isMenuOpen: boolean }>`
  position: fixed;
  overflow: hidden;
  inset: 0px;
  z-index: 10000;
  pointer-events: ${({ $isMenuOpen }) => ($isMenuOpen ? "auto" : "none")};

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100vh;

  opacity: ${({ $isMenuOpen }) => ($isMenuOpen ? "1" : "0")};
  transition: opacity 0.2s ease-in-out;
  background: var(--color-modalOverlay);
`;

export const Menu = styled.nav<{ $isMenuOpen: boolean }>`
  z-index: 10005;

  height: 100vh;

  position: absolute;
  top: 0;
  left: 0;
  transition: transform 0.2s ease-in-out;
  width: 80%;
  transform: ${({ $isMenuOpen }) =>
    $isMenuOpen ? "translateX(0)" : "translateX(-100%)"};

  ${screenSize.minTablet`
    width: auto;
  `}
`;

export const Content = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  background: var(--color-background);
  padding: 1.25rem;
  justify-content: center;
  align-items: center;

  a {
    color: var(--color-text);
    text-decoration: none;
    transition: color 0.2s linear;

    ${screenSize.minTablet`
    font-size: 1.25rem;
  `}
  }
`;

export const Emoji = styled.span`
  margin-right: 10px;
`;

export const MenuItemLink = styled.a`
  color: var(--color-text);
  font-size: ${lFont};
  line-height: 1.6;
  font-weight: 600;
  font-family: ${fontFamilyBrand};
  margin-bottom: ${spacing.md};
`;

export const SubscribeButton = styled(DefaultSubscribeButton)`
  margin: 1.25rem auto;
`;

export const ToggleContainer = styled.div`
  height: 1.5rem;
  width: 1.5rem;
  margin: 1.25rem auto;
`;
