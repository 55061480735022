import * as React from 'react';
import PropTypes from 'prop-types';

export default function Cross24(props) {
  const { colour } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
        stroke="inherit"
        fill={colour}
      />
      <path d="M0 0h24v24H0z" fill="none" />
    </svg>
  );
}

Cross24.propTypes = {
  colour: PropTypes.string,
};

Cross24.defaultProps = {
  colour: 'black',
};
