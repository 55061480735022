import * as React from 'react';

export const SliderContext = React.createContext();

/**
 * Coupled to Slider.js component.
 * Exposes functions to go to next or previous page and get current page
 */
const useSliderControls = () => {
  const { activePage, setActivePage, pages } = React.useContext(SliderContext);

  // Let parent slider component know a child we care about has mounted
  const registerPage = React.useCallback(uniqueName => pages.push(uniqueName), [
    pages,
  ]);

  // Let parent know that we not longer want to track specific child
  const deregisterPage = React.useCallback(
    uniqueName => {
      const idx = pages.findIndex(page => page === uniqueName);
      pages.splice(idx, 1);
    },
    [pages]
  );

  const nextPage = () => {
    const currentPageIndex = pages.findIndex(
      pageName => activePage === pageName
    );

    if (currentPageIndex < pages.length - 1) {
      setActivePage(pages[currentPageIndex + 1]);
    }
  };

  const previousPage = () => {
    const currentPageIndex = pages.findIndex(
      pageName => activePage === pageName
    );

    if (currentPageIndex > 0) {
      setActivePage(pages[currentPageIndex - 1]);
    }
  };

  return {
    nextPage,
    previousPage,
    activePage,
    registerPage,
    deregisterPage,
    setActivePage,
  };
};

export default useSliderControls;
