import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import * as Styled from "./CtaButton.styled";

const buttonTypeMap = {
  solid: Styled.SolidButton,
  solid_borderless: Styled.SolidBorderless,
  outline: Styled.OutlineButton,
  link: Styled.LinkButton,
  square: Styled.SquareButton,
};

export interface CtaButtonProps {
  colourName?: string;
  appearance?: "solid" | "outline" | "link" | "square" | "solid_borderless";
  disabled?: boolean;
  loading?: boolean;
  className?: string;
  children?: React.ReactNode;
}

// This is a visual button only
// For the actual component see actionlink.
export default function CtaButton(props: CtaButtonProps) {
  const {
    children,
    colourName = "mainBrandColour",
    className,
    appearance = "solid",
    disabled = false,
    loading = false,
    ...rest
  } = props;

  const Button = appearance
    ? buttonTypeMap[appearance === "solid" ? "square" : appearance]
    : Styled.SolidButton;

  let ActualChildren;

  if (loading) {
    ActualChildren = <CircularProgress size={14} color="inherit" />;
  } else if (typeof children === "string") {
    ActualChildren = <Styled.TextSmallP>{children}</Styled.TextSmallP>;
  } else {
    ActualChildren = children;
  }

  return (
    <Button
      colourName={colourName}
      disabled={disabled}
      className={className}
      {...rest}
    >
      {ActualChildren}
    </Button>
  );
}
