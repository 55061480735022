import { Subject } from "types/attheminute";
import * as React from "react";

const SubjectTreeContext = React.createContext<any>([]);

export function useSubjectTree() {
  return React.useContext(SubjectTreeContext);
}

export function SubjectTreeProvider({
  subjectTree,
  children,
}: {
  children: React.ReactNode;
  subjectTree: Subject[];
}) {
  return (
    <SubjectTreeContext.Provider value={subjectTree}>
      {children}
    </SubjectTreeContext.Provider>
  );
}
